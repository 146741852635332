import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import "../../css/typography-roboto.css"

export default function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <>
      <link
        href="https://static.convaise.com/popup/bvk/popup-v4-bvk-karriere.min.css?v=1.0.0"
        rel="stylesheet"
      ></link>
      <div
        id="convaise-wrapper"
        class="convaisecss-clear"
        data-chat-height="600px"
        data-chat-width="380px"
        data-name="Karriere-Assistent"
        data-gdpr-mode="overlay"
        data-persist-chat="true"
        data-show-name-button="false"
        data-news="false"
        data-botid="ddxur44gkzqviz2q"
        data-src="https://bots.convaise.com/bvk/karriereseite-iframe/"
        data-avatar-url="https://static.convaise.com/avatars/bvk/chatIcon.svg?v=1.0.0"
      />
      <Helmet>
        <script
          src={withPrefix("popup-v4-bvk-karriere.min.js")}
          type="text/javascript"
        />
      </Helmet>
    </>
  )
}
